import React, {FC, useState} from "react";
import classes from './floatingLabel.module.css';
import useFloatingLabel from "./useFloatingLabel";
import {setIsEngravingType, setRingOptionsType} from "../../types";

export interface FloatingLabelType {
    label : string;
    maxLength: number;
    labelId: string;
    keyName: string;
    value: string;
    setRingOptions:setRingOptionsType,
    setIsEngraving:setIsEngravingType,
    options:{[key:string]:string},
    activeIndex:number,
    isHandView: boolean
}

const FloatingLabel:FC<FloatingLabelType> = (props) => {
    const { options,setIsEngraving, label,labelId, maxLength ,setRingOptions,keyName,value, ...rest } = props;
    const {inputValue ,handleChange,handleKeyUp} = useFloatingLabel(props);
    return (
        <div className={classes.root}>
            <input type="text"
                   id={labelId}
                   placeholder={label}
                   className={`${classes.input}`}
                   onChange={handleChange}
                   value={value ? value : ''}
                   maxLength = {maxLength}
                   onKeyUp={handleKeyUp}
            />
            <span className={classes.counter}>{inputValue.length} / {maxLength}</span>
            <label htmlFor={labelId}
                   className={classes.label}
            >
                {label}
            </label>
        </div>
    )
}
export default FloatingLabel;
