import React, {Fragment, FC, useEffect, useRef, useState, Suspense} from "react";
import classes from "./StartWithSetting.module.css";
import Filter from "../Filter";
import StartWithSettingFilter from "../StartWithSettingFilter";
import {RootState} from "../../reducers/RootReducer";
import {
  setStartWithSettingFilterLoading,
  setStartWithSettingFilterSuccess,
  setStartWithSettingFilterFail,
  setResetStartWithSettingFilter,
  setStartWithSettingProductLoading,
  setStartWithSettingProductSuccess,
  setStartWithSettingProductFail,
  setFilterMinMaxProperty,
  setAfterDiamondSelectOptions,
  setSwatchColor,
  setStartWithSettingFilter,
  setOptionsByProduct,
  setSelectedProduct,
  setMaximumCaratSize
} from "../../action/StartWithSettingFilterAction";
import {setDiamondDetailsLoading,setDiamondDetailsSuccess,setDiamondDetailsFail} from '../../action/DiamondAction'
import {setIsShowFooter} from '../../action/FooterAction'
import {connect, ConnectedProps} from "react-redux";
import Loader from "../Loader/Loader";
import Icon from "../Icons/Icon";
import useStartWithSetting from "./useStartWithSetting";
import {setTooltipDataLoading,SetTooltipDataSuccess,SetTooltipDataError} from '../../action/TooltipAction';
import { PropsFromScreenManager } from '../../types';
import Header from "../Header/Header";
import StickyFooter from "../StickyFooter/StickyFooter";
import {setExpandedRow} from '../../action/TableAction';
import {setRingOptions,setSelectedRingDetails,setSelectedDiamondLoading, setSelectedDiamondFail} from '../../action/RingAction';
import {setFilter} from '../../action/FilterAction';

const mapStateToProps = (state: RootState) => ({
  settingFilter: state.settingFilter,
  diamond: state.diamond,
  tooltip:state.tooltip,
  ring:state.ring,
  table:state.table,
  filter:state.filter
});

const mapDispatchToProps = {
  setStartWithSettingFilterLoading,
  setStartWithSettingFilterSuccess,
  setStartWithSettingFilterFail,
  setResetStartWithSettingFilter,
  setStartWithSettingProductLoading,
  setStartWithSettingProductSuccess,
  setStartWithSettingProductFail,
  setFilterMinMaxProperty,
  setDiamondDetailsLoading,
  setDiamondDetailsSuccess,
  setDiamondDetailsFail,
  setIsShowFooter,
  setTooltipDataLoading,
  SetTooltipDataSuccess,
  SetTooltipDataError,
  setAfterDiamondSelectOptions,
  setSwatchColor,
  setExpandedRow,
  setRingOptions,
  setSelectedRingDetails,
  setStartWithSettingFilter,
  setSelectedDiamondLoading,
  setSelectedDiamondFail,
  setFilter,
  setOptionsByProduct,
  setSelectedProduct,
  setMaximumCaratSize
};

const connector = connect(mapStateToProps, mapDispatchToProps);
export type PropsFromStartWithSettingRedux = ConnectedProps<typeof connector>;

const StartWithSetting:FC<PropsFromStartWithSettingRedux & PropsFromScreenManager> = (props) => {
  const { instanceData, settingFilter } = props ;

  const {
    dropdownRef,
    sortBy,
    isDropdownOpen,
    options,
    selectedImages,
    handleSwatchClick,
    handleDropdownToggle,
    handleRESETFilters,
    handleOptionClick,
    handleClickOutside,
    swatches,
    isMobileLayout,
    handleProduct,
    handleScrollTopClick,
    isLargeTablet,
    showButton,
    scrollRoot
  } = useStartWithSetting(props)

  useEffect(()=>{
    if(settingFilter.selectedProduct && settingFilter.scrollTop && !settingFilter.isProductLoading && !!(document.querySelector(`div[id='scrollToTop']`))){
        document.querySelector(`div[id='scrollToTop']`)?.scrollIntoView({
        behavior: 'smooth', block: 'center', inline: 'start',
      })
    }
  },[settingFilter.scrollTop,settingFilter.isProductLoading])

  if(settingFilter.isLoading){
    return <Loader/>
  }
  if(settingFilter.error){
    return <div>error...</div>
  }
  return (
    <div className={classes.main}>
      <Header instanceData={instanceData}/>
      <div className={classes.root} ref={scrollRoot}>
        {
          (isLargeTablet) ? <Filter isSetHeight={Object.keys(settingFilter.optionsData).length > 0}
                                    isShowFilter={true}
                                    isMobileLayout={(isMobileLayout)}
                                    isReset={Object.keys(settingFilter.options).length > (Object.keys(settingFilter.afterDiamondSelectedOptions).length > 0 ? 3 : 1)}
                                    handleRESETFilters={handleRESETFilters}
          >
            <StartWithSettingFilter options={settingFilter.optionsData}/>
          </Filter> :<div className={classes.filterWrp}><div className={classes.filterWrpInner}><StartWithSettingFilter options={settingFilter.optionsData}/></div></div>
        }
        {/*<Filter isSetHeight={(isMobileLayout)?true:Object.keys(settingFilter.optionsData).length > 0}
                isShowFilter={true}
                isReset={(Object.keys(settingFilter.options).length) > (Object.keys(settingFilter.afterDiamondSelectedOptions).length > 0 ? 4: 2)}
                handleRESETFilters={handleRESETFilters}
                isMobileLayout={(isMobileLayout)}
        >
          <StartWithSettingFilter options={settingFilter.optionsData}/>
        </Filter>*/}
        <div className={classes.product_main}>
          <div className={classes.topbar}>
            <div className={classes.container}>
              <div className={classes.topbar_flex}>
                <div className={'flex gap-2 align-center items-center'}>
                  <span className={classes.topbar_count}>{settingFilter.products.length} Ring Settings</span>
                  {
                    ((Object.keys(settingFilter.options).length) > (Object.keys(settingFilter.afterDiamondSelectedOptions).length > 0 ? 3: 1)) ?
                    (<div className={' flex gap-2 items-center'}>
                          <span className={classes.separator}> | </span>
                          <button type="button" className={classes.filterReset} onClick={handleRESETFilters}>
                            <span className="text-padding-top">Reset</span>
                          </button>
                     </div>):('')
                  }
                </div>

                <div className={classes.topbar_sorting}>
                  {/*<Icon name={"Sort"} />*/}
                  <span className={classes.sortLabel}>Sort By:</span>
                  <div className={classes.dropdown} ref={dropdownRef}>
                    <div className={classes.dropdown_header} id={"sortby_dropdown_header"} tabIndex={0}
                         onKeyDown={(event) => {
                           if (event.target instanceof HTMLElement && event.key === "Enter") {
                             handleDropdownToggle()
                           }
                         }}
                         onClick={()=>handleDropdownToggle()}
                    >
                      {sortBy}
                      <span className={`${classes.dropdownIcon} ${isDropdownOpen ? classes.isActive : ''}`}><Icon name="hk-chevron-down_M"/></span>
                    </div>

                      <ul id={'sortby_dropdown_options'} className={`${classes.dropdown_options} ${isDropdownOpen ? classes.isOpen : ''}`}>
                        {options.map((option) => (
                          <li key={option} tabIndex={isDropdownOpen ? 0 : -1} onClick={() => handleOptionClick(option)}
                              // onKeyPress={() => handleOptionClick(option)}
                              onKeyDown={(event) => {
                                 if(event.target instanceof HTMLElement && event.key === "Enter"){
                                   handleOptionClick(option)
                                 }
                                 (event.key === "Escape") && handleClickOutside(true);
                              }}
                              className={`${sortBy === option ? classes.liselected : ''}`}>
                            <span className={classes.optionText}>
                              {option}
                            </span>
                          </li>
                        ))}
                      </ul>

                  </div>
                </div>
              </div>
            </div>
          </div>
          {!settingFilter.isProductLoading ? <section className={classes.grid_wrapper}>
            <div className={classes.grid_inner} id={"listing_inner_grid"}>
              {settingFilter.products.map((product,index ) => (
                    <div
                        id={settingFilter.selectedProduct === product.Code ? 'scrollToTop' : ''}
                        key={`${index}_${product.Code}`}
                        tabIndex={0}
                        onClick={() => handleProduct(product.Code, product)}
                        // onKeyPress={()=>handleProduct(product.Code,product)}
                        onKeyDown={(event) => {
                          if (event.key === "Enter") {
                            handleProduct(product.Code, product)
                          }
                        }}
                        className={classes.collection}>
                      <div className={classes.collection_item}>
                        <div
                            className={classes.brandTitle}>{product.design_origin !== 'Generic' ? product.design_origin === "Reve" ? 'rêve' : product.design_origin : null} </div>
                        <div className={classes.collection_product}>
                          <div className={classes.collection_image}>
                            <img
                                src={selectedImages[product.Code]?.imagePath}
                                alt={product?.Name}
                                className={classes.image}
                                loading={"lazy"}
                            />
                          </div>
                          <div className={classes.content}>
                            <ul className={classes.swatches}>
                              {product.swatches && (
                                  <>
                                    {Object.entries(product.swatches).map(([key, value]: any, index: number) => {
                                      return (
                                          <Fragment key={product.id + '.' + index}>
                                            {value ? <li
                                                tabIndex={0}
                                                className={`${classes.swatches_item} ${selectedImages[product.Code]?.swatch === key ? classes.selected : ''}`}

                                                onClick={(e) => {
                                                  e.stopPropagation();
                                                  handleSwatchClick(product.Code, product.images[key], key);
                                                }}
                                                onKeyDown={(e) => {
                                                  if (e.key === "Enter" || e.key === "Spacebar" || e.key === " ") {
                                                    handleSwatchClick(product.Code, product.images[key], key);
                                                    e.preventDefault();
                                                  }
                                                }}
                                                title={swatches[key]}
                                            >
                                              <img key={index}
                                                   src={swatches[key]}
                                                   alt={`Swatch ${index + 1}`}
                                              />
                                            </li> : null}
                                          </Fragment>
                                      )
                                    })}
                                  </>
                              )}
                            </ul>
                            <h3 className={classes.title}>{product.Name} Setting</h3>
                            <p className={classes.desc}>
                              <span>Starting at </span>
                              <span
                                  className={classes.price}>{product.price_at ? `$${product.price_at.toLocaleString("en-US")}` : '-'} </span>
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                )
              )
              }
            </div>
          </section>:<Loader/>}
        </div>
        {/*onClick={scrollToTop}*/}
        <button onClick={handleScrollTopClick}
                className={`stickyButton ${showButton ? 'active' : ''}`}
                aria-label={"Scroll to top"}
                onKeyDown={(eve)=> {

                }}
        >
          <span className={'iconTop'}><Icon name={'hk-arrow-up-forward'}/></span>
        </button>
      </div>
      <StickyFooter instanceData={instanceData}/>
    </div>
  );
};
export default connector(StartWithSetting);
