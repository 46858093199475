import React, {FC, Fragment} from 'react'
import classes from './HeaderTab.module.css';
import {Menu, Transition} from '@headlessui/react'
import Icon from "../../Icons";
import {CheckMark} from "../../Images";
import UseHeaderTab from './useHeaderTab';
import {RootState} from "../../../reducers/RootReducer";
import {connect, ConnectedProps} from "react-redux";
import {setHeaderTabDefault,setDiamondTabIconTrue, setHeaderTabToSetting,setSettingTabIconTrue,setBothTabIconTrue,resetPath,changeBackClick} from '../../../action/HeaderAction';
import { setInitialOption, setIsInitialOption } from '../../../action/RingAction';
import {setIsShowFooter,setInitialFooter,setStyleID} from '../../../action/FooterAction';
import {HeaderProp, PropsFromScreenManager} from "../../../types";
import {setInitialStartWithSettingData} from '../../../action/StartWithSettingFilterAction';
import {setInitialDiamondOption} from '../../../action/DiamondAction';
import {setInitialFilter} from '../../../action/FilterAction';
import {setTableInitialState} from '../../../action/TableAction'

const mapStateToProps = (state: RootState) => ({
    header: state.header,
    ring:state.ring,
    diamond:state.diamond,
    footer:state.footer,
});

const mapDispatchToProps = {
    setHeaderTabToSetting,
    setInitialOption,
    setIsShowFooter,
    setDiamondTabIconTrue,
    setHeaderTabDefault,
    setSettingTabIconTrue,
    setInitialStartWithSettingData,
    setInitialDiamondOption,
    setBothTabIconTrue,
    setInitialFooter,
    setInitialFilter,
    setTableInitialState,
    setStyleID,
    resetPath,
    changeBackClick,
    setIsInitialOption
};

const connector = connect(mapStateToProps, mapDispatchToProps);

export type PropsFromHeaderTabRedux = ConnectedProps<typeof connector>;

const HeaderTab: FC<HeaderProp & PropsFromScreenManager> = (props) => {
    const { label, isActive, isDisabled, isIcon,backButton,openTab,isDisabledTab,diamond,iframeLoadActionCount} = props;
    const {handleChangePath,tabData,handleBack} = UseHeaderTab(props);
    const disableDYO = (diamond.details && (diamond.details.shape as string)?.toLowerCase() === "marquise" && diamond.details.carat &&  +diamond.details.carat >= 1.75 && !window.location.hash.includes('#diamond') && !window.location.hash.includes('#details'))
    if (backButton) {
        return (
            <div
                className={`${classes.tab} ${!(isDisabled || isDisabledTab) && isActive ? classes.active : ''} ${(isDisabled || isDisabledTab) ? classes.disabled : ''}`}
                onClick={()=> (isDisabled || isDisabledTab) ? '' : handleBack(label)}
                onKeyDown={(event: React.KeyboardEvent<HTMLDivElement>) => {
                    if (event.code === 'Enter' || event.code === 'Space') {
                        if(!(isDisabled || isDisabledTab)) {
                            handleBack(label)
                        }
                    }
                }}
                tabIndex={(isDisabled || isDisabledTab) ? -1 : isActive ? -1 : 0}
            >
                {label}
                {isIcon && <img src={CheckMark} className={`${isDisabled ? 'opacity-[0.30]': ''} w-5 h-5`}/>}
            </div>
        )
    } else {
        return (
            <Menu as="div" className={classes.menuMain}>
                <Menu.Button className={`${classes.tab} ${isActive ? classes.active : ''}`} disabled={(isDisabled || isDisabledTab)}>
                    {label}
                    {isIcon ? <img src={CheckMark} className={`${(isDisabled || isDisabledTab) ? 'opacity-[0.30]': ''} w-5 h-5`}/> : <Icon name={'hk_chevron_down'}/>}
                </Menu.Button>
                <Transition
                    as={Fragment}
                    enter="transition ease-out duration-100"
                    enterFrom="transform opacity-0 scale-95"
                    enterTo="transform opacity-100 scale-100"
                    leave="transition ease-in duration-75"
                    leaveFrom="transform opacity-100 scale-100"
                    leaveTo="transform opacity-0 scale-95"
                >
                    <Menu.Items className={classes.menuItems}>
                        <div className={classes.menuWrap}>
                            {
                                tabData.map((tab,index)=>{
                                    if(tab.key !== openTab){return (
                                        <Menu.Item key={index}>
                                            <button
                                                className={`${classes.dialogDetail} ${ (!!(tab.heading === 'Design Your Own' && disableDYO) || !!(iframeLoadActionCount && iframeLoadActionCount > 0)) ? classes.disableEvent : ''}`}
                                                disabled={!!(tab.heading === 'Design Your Own' && disableDYO) || !!(iframeLoadActionCount && iframeLoadActionCount > 0)}
                                                onClick={() => handleChangePath(tab.key)}
                                            >
                                                <img src={tab.imagePath} className={classes.dialogImg}
                                                     alt={tab.imageAlt}/>
                                                <div className={classes.dialogText}>
                                                    <h2 className={classes.dialogTitle}>{tab.heading}</h2>
                                                    <p className={classes.dialogParagraph}>{tab.description}</p>
                                                </div>
                                            </button>
                                        </Menu.Item>
                                    )
                                    }
                                })
                            }
                        </div>
                    </Menu.Items>
                </Transition>
            </Menu>
        )
    }
}
export default connector(HeaderTab);
