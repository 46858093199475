export default {
   RING_SELECTED_OPTIONS: 'RING_SELECTED_OPTIONS',
   RING_OPTIONS_DATA: 'RING_OPTIONS_DATA',
   RING_EXTRA_DATA: 'RING_EXTRA_DATA',
   RING_OPTIONS_LOADING: 'RING_OPTIONS_LOADING',
   RING_OPTIONS_FAIL: 'RING_OPTIONS_FAIL',
   RING_OPTIONS_DEFAULT:'RING_OPTIONS_DEFAULT',
   SET_START_WITH_SETTING_SELECTED_OPTIONS: 'SET_START_WITH_SETTING_SELECTED_OPTIONS',
   SET_SELECTED_DIAMOND_DATA: 'SET_SELECTED_DIAMOND_DATA',
   SET_SELECTED_DIAMOND_LOADING: 'SET_SELECTED_DIAMOND_LOADING',
   SET_SELECTED_DIAMOND_FAIL: 'SET_SELECTED_DIAMOND_FAIL',
   SET_INITIAL_RING_OPTIONS: 'SET_INITIAL_RING_OPTIONS',
   SELECTED_RING_DETAILS: 'SELECTED_RING_DETAILS',
   BACK_DIAMOND_SELECTION_OPTIONS: 'BACK_DIAMOND_SELECTION_OPTIONS',
   SET_REMOVE_RING_OPTION: 'SET_REMOVE_RING_OPTION',
   SET_BAND_TAB_INDEX: 'SET_BAND_TAB_INDEX',
   SET_JOURNEY_STARTED_FROM:'SET_JOURNEY_STARTED_FROM',
   SET_RING_PRICE_LOADING: 'SET_RING_PRICE_LOADING',
   SET_RING_PRICE: 'SET_RING_PRICE',
   SET_RING_PRICE_ERROR: 'SET_RING_PRICE_ERROR',
   SET_EXCLUSION_OPTION: 'SET_EXCLUSION_OPTION',
   SET_IS_HAND_LOADED: 'SET_IS_HAND_LOADED',
   SET_IS_ENGRAVING : 'SET_IS_ENGRAVING',
   SET_IS_INITIAL_OPTION : 'SET_IS_INITIAL_OPTION',
   SET_BACK_FROM_DIAMOND:'SET_BACK_FROM_DIAMOND'
}
