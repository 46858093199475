import React, {FC, useCallback, useEffect, useState} from "react";
import Button from "../../Button/Button";
import {ExpandRowDetailsProps, PropsFromScreenManager, TableData} from "../../../types";
import classes from "./ExpandRowDetails.module.css";
import DiamondDetail from "../DiamondDetail";
import placeholder from "../../../Utils/images/placeholder.png";
import {RootState} from "../../../reducers/RootReducer";
import {
    recentDataSuccess,
    toggleScrollTop
} from "../../../action/TableAction";
import {
    setDiamondDetailsLoading, setDiamondDetailsFail,
    setDiamondDetailsSuccess, setInitialDiamondOption
} from "../../../action/DiamondAction";
import {connect, ConnectedProps} from "react-redux";
import DialogBox from "../../DialogBox";
import {HKCustomize, HKRing} from "../../Images";
import {setFooterToggle, setIsShowFooter} from '../../../action/FooterAction'
import {setDiamondTabIconTrue} from '../../../action/HeaderAction';
import {ToLocalStringConvert} from "../../../Utils/toLocalStringConvert";
import {setExclusionOption,setJourneyStartedFrom,setSelectedDiamondLoading,setSelectedDiamondFail,setSelectedDiamondData,setBackFromDiamond} from '../../../action/RingAction';
import {getDiamondCode} from "../../../Utils/getDiamondCode";
import { buildYourOwnValue, findNearestValue } from '../../../Utils/findNearestCaratValue';
import dialogTitleImg from "../../../Utils/images/hk-ring.png"
import { getCenterDiamondSize } from '../../../Utils/getExclusionDiamondSize';
import { exclusionMatrixData, getExclusionOptions } from '../../../Utils/getExclusionOptions';
import { initialExclusionKey } from '../../../Utils/initialExclusionKey';
import { setIsCartLoading } from '../../../action/CartAction';
const mapStateToProps = (state: RootState) => ({
    table: state.table,
    ring: state.ring,
    header: state.header,
    diamond: state.diamond,
    footer:state.footer,
    cart:state.cart,
});

const mapDispatchToProps = {
    recentDataSuccess,
    setDiamondDetailsLoading,
    setDiamondDetailsFail,
    setDiamondDetailsSuccess,
    setInitialDiamondOption,
    setFooterToggle,
    setDiamondTabIconTrue,
    setIsShowFooter,
    setJourneyStartedFrom,
    setSelectedDiamondLoading,
    setSelectedDiamondFail,
    setSelectedDiamondData,
    toggleScrollTop,
    setExclusionOption,
    setIsCartLoading,
    setBackFromDiamond
};
const connector = connect(mapStateToProps, mapDispatchToProps);
export type PropsFromRowDetailsRedux = ConnectedProps<typeof connector>;
const ExpandRowDetails: FC<ExpandRowDetailsProps&PropsFromScreenManager> = (props) => {
    const {
        ring,
        diamond,
        row,
        footer,
        recentDataSuccess,
        setInitialDiamondOption,
        setFooterToggle,
        setDiamondDetailsSuccess,
        setDiamondTabIconTrue,
        setIsShowFooter,
        instanceData:{screenManager},
        setJourneyStartedFrom,
        setSelectedDiamondLoading,
        setSelectedDiamondFail,
        setSelectedDiamondData,
        toggleScrollTop,
        setExclusionOption,
        setIsCartLoading,
        cart,
        setBackFromDiamond
    } = props;
    const {image_link, carat, source: rowSource, shape, b2c_price:diamond_b2c_price, cut, color, clarity} = row;
    const rpid = localStorage.getItem('rpid');
    const builder_mode = localStorage.getItem('builder_mode');
    const uuid = localStorage.getItem('design_uuid');
    const handleDesignYourOwnEngagement = (tabValue: string) => {
        document.body.classList.remove('sticky_filter');
        toggleScrollTop(true);
        recentDataSuccess(row);
        setInitialDiamondOption();
        setDiamondDetailsSuccess(row);
        setJourneyStartedFrom('diamond');
        if(tabValue !== 'Start With an Existing Setting'){
            setDiamondTabIconTrue('dyo');
            pathChange('dyo', 'RB001',`${row.id}`);
        }else {
            setDiamondTabIconTrue('setting');
          pathChange('setting', null,`${row.id}`);
        }


        setIsShowFooter(true);
    }

  const dialogDetails = [
      {
          title: `Design Your Own Setting`,
          img: HKCustomize,
          paragraph: `Design your own engagement ring by customizing your ring’s style, setting, metal and so much more.`,
          // handleClick: handleDesignYourOwnEngagement,
      },
    {
      title: `Start With an Existing Setting`,
      img: HKRing,
      paragraph: `Select a ring setting from our exclusive curation.`,
      // handleClick: handleDesignYourOwnEngagement
      // handleClick: handleContinueWithSetting,
    },
  ];
  const [isOpenDialog, setIsOpenDialog] = useState(false);
  const closeDialogBox = useCallback(() => {
    setIsOpenDialog(false);
  },[setIsOpenDialog])
  const openDialogBox = useCallback(() => {
    setIsOpenDialog(true);
  },[setIsOpenDialog])

  const handleSelectDiamond = async (row:TableData) => {
      setJourneyStartedFrom('diamond');
      setDiamondDetailsSuccess(row);
    // recentDataSuccess(row);
    if(Object.keys(ring.options).length > 0 ){
      setFooterToggle();
      setIsCartLoading(true);
      const exclusionMatrix = await exclusionMatrixData(footer.styleID);
      const {shape,carat,source} = row;
      let isExclusionDiamond = false ;
      if(shape && carat && source){
        isExclusionDiamond = ring.exclusionOption.includes(findNearestValue(+carat));
        const result = await getDiamondCode({"shape":shape as string,"type":source as string},setSelectedDiamondLoading,setSelectedDiamondFail);
        if(result) {
          if(result["Diamond_Shape"] !== ring.options["Diamond_Shape"]){
            const ringExclusionMatrix = getExclusionOptions(exclusionMatrix ,"Diamond_Shape",result["Diamond_Shape"],initialExclusionKey(footer.styleID.includes('RP')),ring.options);
            isExclusionDiamond = [...Object.values(ringExclusionMatrix).flat()]?.includes(findNearestValue(+carat));
            setExclusionOption([...Object.values(ringExclusionMatrix).flat()]);
            setSelectedDiamondData({...result,Center_Diamond_Size: isExclusionDiamond ? getCenterDiamondSize([...Object.values(ringExclusionMatrix).flat()]) : findNearestValue(+carat)});
          } else {
            setSelectedDiamondData({...result,Center_Diamond_Size: isExclusionDiamond ? getCenterDiamondSize(ring.exclusionOption) : findNearestValue(+carat)});
          }
        }
      }
      setIsCartLoading(false);
    }else {
      openDialogBox();
    }
    if ((screenManager as any).props.setStateManagerData) {
      (screenManager as any).props.setStateManagerData('selectedDiamond', row);
    }
  };

  const pathChange = (path: string,styleId?:string|null, id?: string) => {
    if ((screenManager as any).changeScreen) {
      (screenManager as any).changeScreen({
        viewName: path,
        styleId :styleId,
        id: id ,
      });
    }
  };

  const handleBack = useCallback(()=>{
      if(Object.keys(diamond.details).length > 0){
        setInitialDiamondOption();
      }
      setBackFromDiamond(true);
      pathChange('dyo',footer.styleID);
  },[pathChange,setInitialDiamondOption,diamond.details,footer.styleID,setBackFromDiamond])

  const handleViewDiamondDetail = useCallback((row:TableData)=>{
      recentDataSuccess(row);
      pathChange('details',null, row.id as string);
      toggleScrollTop(true);
  },[recentDataSuccess,pathChange,toggleScrollTop])

  return (
    <>
      <div className={classes.root}>
        <div className={classes.container}>
          {/* <img src={image} className={classes.img} alt="Product Image" /> */}
          <div className={classes.img} style={{backgroundImage: `url(${image_link ? image_link : placeholder})`}}  aria-label="Product Image" />
        </div>
        <div className={classes.info}>
          <DiamondDetail
            carat={carat}
            title={`${carat} ct. ${shape} ${rowSource} Diamond`}
            price={ToLocalStringConvert(diamond_b2c_price as number)}
            cut={cut}
            color={color}
            clarity={clarity}
          />
        </div>
        <div className={classes.detailsWrap}>
          <div className={classes.details}>
            <Button
              type={"button"}
              buttonType={"btnPrimary"}
              className={classes.detailButton}
              onClick={()=>handleSelectDiamond(row)}
            >
              {/*<Icon name={'hk-viewDiamond'} />*/}
              <span className={'text-padding-top-25'}>Select This Diamond</span>
            </Button>
            <Button
                type={"button"}
                className={classes.detailButton}
                onClick={() => handleViewDiamondDetail(row)}
            >
                <span>View Diamond Details</span>
                {/*<Icon name={'hk_chevron_right'} />*/}
            </Button>
              {Object.keys(ring.options).length > 0 && !(rpid && uuid) && builder_mode !== "edit" && !footer.isRedirectToEdit ?
                  (<Button type="button" className={`${classes.detailButton} ${classes.backButton}`}
                       onClick={handleBack}>
                     <span>Back to Select Setting</span>
                  </Button>):null
              }
          </div>
        </div>
      </div>
      <DialogBox
          isOpen={isOpenDialog}
          closeModal={closeDialogBox}
          titleClassName={classes.dialogBoxTitle}
          isIcon={true}
          title={""}
      >
          <div className={classes.dialogSetting}>
              <img className={classes.dialog_img} src={dialogTitleImg} alt={"Settings"} />
              <p className={classes.dialog_subtitle}>Our Settings</p>
              <p className={classes.dialog_text}>Design your own setting or choose <br/> a pre-crafted setting.</p>
              <div className={classes.buttonGroup}>
                  {
                      dialogDetails.map((detail, index) => {
                          return (
                              <Button key={index} type={"button"} className={classes.btnSetting}
                                      isDisabled={detail.title === 'Design Your Own Setting' && (row.shape as string)?.toLowerCase() === "marquise" &&  row  && row.carat &&  +row.carat >= 1.75 ? true : false}
                                      onClick={() => {
                                  handleDesignYourOwnEngagement(detail.title === 'Start With an Existing Setting' ? 'Start With an Existing Setting' : 'Design Your Own Setting')
                              }}><span>{detail.title}</span></Button>
                          );
                      })
                  }
              </div>
          </div>
      </DialogBox>
    </>
  );
};

export default connector(ExpandRowDetails);
