import React, { FC,Fragment } from "react";
import classes from "./StartWithSettingFilter.module.css";
import Label from "../Label/Label";
import SlickSlider from "../Slider";
import Button from "../Button";
import {RootState} from "../../reducers/RootReducer";
import {setChangeMinMaxValue,setStartWithSettingFilter,removeFilterKey,setScrollTop,setSelectedProduct} from "../../action/StartWithSettingFilterAction";
import {connect, ConnectedProps} from "react-redux";
import useStartWithSettingFilter from "./useStartWithSettingFilter";
import InputRangeSlider from "../InputSlider";
import {SOLITAIRE,
  HALO,
  THREE_STONE,
  NATURE,
  SIDE_STONE, REVE, JENNY} from '../Images'
import { settingOptions, StartWithSettingFilterType} from "../../types";
import {ColorImg, DiamondShapeContent} from "../../Utils/commonData";


const mapStateToProps = (state: RootState) => ({
  settingFilter: state.settingFilter,
  diamond:state.diamond
});

const mapDispatchToProps = {
  setStartWithSettingFilter,
  removeFilterKey,
  setChangeMinMaxValue,
  setScrollTop,
  setSelectedProduct
};
const connector = connect(mapStateToProps, mapDispatchToProps);

export type PropsFromStartWithSettingContentRedux = ConnectedProps<typeof connector>;

const StartWithSettingFilter: FC<StartWithSettingFilterType> = ({
  options,
  settingFilter,
  setStartWithSettingFilter,
  removeFilterKey,
  setChangeMinMaxValue,
  setScrollTop,
  setSelectedProduct,
  diamond
}) => {
  const {handleFilterType,CenterDiamondSize} = useStartWithSettingFilter({setStartWithSettingFilter,settingFilter,removeFilterKey,setScrollTop,setSelectedProduct});

  const getImages:{[key:string]:string} = {
    SOLITAIRE,
    HALO,
    THREE_STONE,
    NATURE,
    SIDE_STONE, REVE, JENNY }
  const renderSliderOrButtons = (key: string, value: {[key:string] : number | string | null}[] | undefined, index: number) => {
    switch (key) {
      case "Diamond_Shape":
      case "Ring_Style":
        let sliderContent = [];
        const removeOptions  = settingFilter.options.Center_Diamond_Type === "GNU" ? ["JENNY","REVE"]
            : (Object.keys(diamond.details).length > 0 && diamond.details.certification !== 'GCAL') ? ["JENNY","REVE"]
                : (Object.keys(diamond.details).length > 0 && diamond.details.certification === 'GCAL' && !diamond.details.scs) ? ["REVE"]
                    : ["LOTUS"]

        if(key === "Ring_Style" ){
          sliderContent = (value as {[key:string] : string}[]).filter(v => !removeOptions.includes(v.Code))?.map((v) => ({
            code: v.Code,
            label: v.Name,
            imgPath: getImages[v.Code]
          }));
        } else {
          sliderContent = (value as {[key:string] : string}[])?.map((v) => ({
            code: v.Code,
            label: v.Name,
            imgPath: DiamondShapeContent.find(shape => shape.code === v.Code)?.imgPath || `${process.env.REACT_APP_PDP_IMAGE_KEY}${v.image_name}`
          }));
        }
        return (
            <div className={classes.items} key={index}>
              <Label text={key.replaceAll("_", " ")}>
                <SlickSlider
                    contents={sliderContent}
                    removeFilterKey={removeFilterKey}
                    handleFilterType={handleFilterType}
                    filter={settingFilter.options}
                    labelKey={key}
                    isMultiSelect={key === 'Ring_Style'}
                    isSingleEnable={false}
                    isNotUpperCase={true}
                    alterSlideConfig = {key === "Ring_Style"}
                    isStaticImage={key === 'Ring_Style'}
                />
              </Label>
            </div>
        );
      case "Setting_Price":
        const filteredValue = settingFilter.options.Setting_Price ? (settingFilter.options.Setting_Price as string).split('-') : undefined;
        return (
            <Fragment key={index} >{Object.keys(settingFilter.minMaxValue).length > 0 ? <div className={classes.items}>
              <Label text={"Setting Price"}>
                <InputRangeSlider
                    min={settingFilter.minMaxValue["Setting_Price"].min}
                    max={settingFilter.minMaxValue["Setting_Price"].max}
                    selectedValue={(filteredValue && filteredValue.length > 0) ? [+filteredValue[0],+filteredValue[1]] : undefined}
                    step={1}
                    isShowDollar={true}
                    keyName={key}
                    handleFilterType={handleFilterType}
                    removeFilterKey={removeFilterKey}
                    options={settingFilter.options}
                    setChangeMinMaxValue={setChangeMinMaxValue}
                    isReset={settingFilter.isReset}
                />
              </Label>
            </div>: null}</Fragment>
        );
     /* case 'Center_Diamond_Size':
        const marks : { [key: number]: string } = (CenterDiamondSize as DiamondSizeValueType[]).reduce((acc, val, index) => {
          return { ...acc, [(100 / (CenterDiamondSize as DiamondSizeValueType[]).length) * index]: val.Name };
        }, {});
        const fIndex = (CenterDiamondSize as any[]).map((v) => v.value).indexOf(settingFilter.options[key]);
        return (
            <div className={`${classes.items} hk_${key.toLowerCase()}`} key={index}>
              <Label text={key.replaceAll('_',' ')} page={'pdpBuilder'}>
                <div className={classes.wrapper}>
                  <DiamondSizeSlider
                      marks={marks}
                      handleAllOptions={handleFilterType}
                      shapeKey={key}
                      fIndex={fIndex}
                      allValue={CenterDiamondSize as centerDiamondSizeType[]}
                      isStartWithSetting={true}
                      isReset={settingFilter.isReset}
                  />
                </div>
              </Label>
            </div>
        )*/
      default:
        return (
            <div className={classes.items} key={index}>
              <Label text={key.replaceAll("_", " ")} page={'pdpBuilder'}>
                {value?.map((v, index: number) => (
                    <Button
                        key={index}
                        isDisabled={(settingFilter.options['Metal'] === 'PT') && (v.Code === "YG" || v.Code === "RG") }
                        buttonType={settingFilter.options && settingFilter.options[key as keyof typeof settingFilter.options] === v.Code ? "active" : undefined}
                        type="button"
                        onClick={() => handleFilterType(v.Code as string, key)}
                        className = {`${classes.button} hk_${key.toLowerCase()}_button`}
                    >
                      {v.image_name && <img className={classes.swatchImg} src={`${process.env.REACT_APP_PDP_IMAGE_KEY}${v.image_name}`} alt={`${v.Name}`} />}
                      {key === "Color" && v.Name && <img className={classes.swatchImg} src={ColorImg[v.Name]} alt={`${v.Name}`} />}
                      <span>{v.Name}</span>
                    </Button>
                ))}
              </Label>
            </div>
        );
    }
  };
  const {Center_Diamond_Type,Diamond_Shape,Ring_Style,Color} = options;
  let orderOptions:settingOptions ={};
  if(Object.keys(options).length > 0){
    const Setting_Price: [] = [];
    // const Center_Diamond_Size: [] = [];
    // Metal and center diamond size was removed from orderOptions based on client requirements
    orderOptions = { Ring_Style,Diamond_Shape,Color,Center_Diamond_Type,Setting_Price}
  }
  return (
    <>
      {Object.entries(orderOptions).map(([key, value], index) => !Object.keys(settingFilter.afterDiamondSelectedOptions).includes(key) && renderSliderOrButtons(key, value, index))}
    </>
  );
};
export default connector(StartWithSettingFilter);
