import React, { FC } from 'react';
import Button from '../Button';
import Icon from '../Icons/Icon';
import MainImageSlider from '../MainImageSlider';
import { RootState } from '../../reducers/RootReducer';
import { setFooterToggle, setRedirectEditPage } from '../../action/FooterAction';
import { setCartImageSuccess, setCartThumbImageSuccess } from '../../action/CartAction';
import { setSettingTabIconTrue, setDiamondTabIconTrue } from '../../action/HeaderAction';
import { setBackToSelectDiamondPage } from '../../action/DiamondAction';
import { connect, ConnectedProps } from 'react-redux';
import { ToLocalStringConvert } from '../../Utils/toLocalStringConvert';
import { PropsFromScreenManager } from '../../types';
import useCart from './useCart';
import {setRingOptions,setRingExtraData,setRemoveRingOption,setBandTabIndex,setPriceLoading,setRingPrice,setRingPriceError} from '../../action/RingAction';
import {toggleScrollTop} from '../../action/TableAction';
import IcoMoonConstant from '../../constant/IcoMoonConstant';
import classes from './Cart.module.css';
import Header from "../Header";
import { buildYourOwnValue } from '../../Utils/findNearestCaratValue';
import { getCenterDiamondSize } from '../../Utils/getExclusionDiamondSize';
import Loader from '../Loader/Loader';
import {setFilter,setAfterRingOptions} from '../../action/FilterAction';

const Cart: FC<PropsFromCartRedux & PropsFromScreenManager> = (props) => {
  const {footer,setFooterToggle,diamond,cart,ring,instanceData,instanceData: { screenManager }} = props;
  const { allDetails, handleReviewClick, Ring_Style,diamond_b2c_price, rpid, uuid, builder_mode,randomPrice,ringPrice,Label,isDisabledBtn} = useCart(props);
  const handleStartAgain = ()=>{
    //@ts-ignore
    window.location.href = window.location.origin;
  }
  if(cart.isCartLoading){
    return <Loader/>
  }
  return (
    <div id={"cartView"}>
      <Header instanceData={instanceData}/>
      <div className={classes.root}>
        <div className={classes.container}>
          <div className={`${classes.slider} sliderRef`}>
            <MainImageSlider is360={false} img={cart.image} isIcon={false} thumbImage={cart.cartThumbImage} isLoading={cart.isLoading} />
          </div>
          <div className={classes.description}>
            <div className={classes.header}>
              {/*<h1 className={classes.heading}>{`${(Label?.toLowerCase() !== "generic" && Label) ? ((Label === 'Reve') ? 'rêve ':`${Label} `) : ''}${Ring_Style} Engagement Ring`}</h1>*/}
              <h1 className={classes.heading}>Your Engagement Ring</h1>
              <div className={classes.heading_price}>{`$${ToLocalStringConvert(diamond_b2c_price ? (+diamond_b2c_price + +ringPrice) : +ringPrice)}`}</div>
            </div>
            <div className={classes.content}>
              <div className={classes.product}>
                {allDetails
                  .filter((details) => Object.keys(details.showDetails).length > 0)
                  .map((details, index) => {
                    return (
                      <div key={index} className={classes.productItem}>
                        <div className={classes.pricewrap}>
                          <h3 className={classes.subtitle}>{details.title}</h3>
                          <h3 className={classes.price}>{details.price}</h3>
                        </div>
                        <div className={classes.accordions}>
                          <div className={classes.buttonWrap}>
                            {details.editAction && (
                              <Button type="button" className={classes.action} onClick={details.editAction}>
                                <Icon name={IcoMoonConstant.HK_SQUARE_PENCIL} />
                                <span className={''}>Edit</span>
                              </Button>
                            )}
                            <Button type="button" className={classes.action} onClick={details.moreAction}>
                              <span className={''}>{details.isShowMore ? 'Less' : 'More'}</span>
                              <Icon name={IcoMoonConstant.HK_CHEVRON_DOWN_M} className={details.isShowMore ? classes.iconswap : ''} />
                            </Button>
                          </div>
                          <ul className={`${classes.acc_wrap} ${details.isShowMore && classes.isActive}`}>
                            {Object.entries(details.showDetails).filter(([key,val]) => footer.styleID.includes('RP') ? !['Ring Style','Ring Crown','Ring Side'].includes(key): [key,val]).map(([key, value], index) => {
                              if(value && value !== "null"){
                                return (
                                    <li className={classes.item} key={index}>
                                      <span className={classes.span}>{key}</span>
                                      {
                                        (key === 'Certificate Link' || key === 'SCS Certificate Link') && value
                                          ?
                                          <a
                                            href={value as string}
                                            target="_blank"
                                            className={`${classes.span} ${classes.spanLink}`}
                                          >
                                            <span className={'text-padding-top'}>View Certificate</span>
                                            <Icon name="hk-arrow-up-forward"/>
                                          </a>
                                          :
                                          <span
                                            className={`${classes.label} ${!(['minimum setting total weight','minimum band total weight','engraving'].includes(key.toLowerCase())) ? classes.capitalizeText : ''}`}>
                                            {value ? value : '-'}
                                          </span>
                                      }
                                    </li>
                                );
                              }
                            })}
                          </ul>
                        </div>
                      </div>
                    );
                  })}
              </div>
              {/*<div className={classes.total_wrap}>
                <div className={classes.total_label}>Total Price</div>
                <div className={classes.total_price}>{`$${ToLocalStringConvert(diamond_b2c_price ? +diamond_b2c_price + +ring.ringPrice + +(randomPrice*(ring.options["Double_Second_Band"] ? 2 : 1)): +ring.ringPrice + +randomPrice*(ring.options["Double_Second_Band"] ? 2 : 1))}`}</div>
              </div>*/}
            </div>
            <div className={classes.footer}>
              <Button
                type="button"
                buttonType="btnPrimary"
                className={classes.primaryAction}
                isDisabled={!(Object.keys(diamond.details).length > 0 && Object.keys(ring.options).length > 0) || isDisabledBtn}
                onClick={handleReviewClick}
              >
                <span>Finalize Your Design</span>
              </Button>
              {/*{Object.keys(diamond.details).length > 0 && Object.keys(ring.options).length > 0 && (
                  <>
                    {(!(rpid && uuid) && builder_mode !== "edit") || !diamond.isDiamondAvailable ?
                      <div className={classes.secondaryAction}>
                        <Button type="button" className={classes.button} onClick={() => setFooterToggle()}>
                          <Icon name={IcoMoonConstant.HK_CHEVRON_LEFT_M} />
                          <span>Back</span>
                        </Button>
                      </div> :
                    null}
                  </>
              )}*/}
              {/*<Button
                    type="button"
                    className={classes.button}
                    onClick={handleStartAgain}
                  >
                    <span>Start Again</span>
                  </Button>*/}
              <ul className={classes.list}>
                <li>
                  Diamond price may change when selected or added to wishlist.
                </li>
                {diamond.details && diamond.details.carat && +diamond.details.carat > buildYourOwnValue[getCenterDiamondSize(ring.exclusionOption)] ? <li>The preview image represents diamonds up to {buildYourOwnValue[getCenterDiamondSize(ring.exclusionOption)]} carats. Larger sizes will not be shown to scale.</li> : ''}
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state: RootState) => ({
  diamond: state.diamond,
  ring: state.ring,
  header: state.header,
  filter: state.filter,
  footer: state.footer,
  cart: state.cart,
  settingFilter:state.settingFilter,
  table:state.table,
  stateManger:state.stateManager
});

const mapDispatchToProps = {
  setFooterToggle,
  setSettingTabIconTrue,
  setDiamondTabIconTrue,
  setRedirectEditPage,
  setCartImageSuccess,
  setCartThumbImageSuccess,
  setBackToSelectDiamondPage,
  toggleScrollTop,
  setRingOptions,
  setRingExtraData,
  setRemoveRingOption,
  setBandTabIndex,
  setFilter,
  setAfterRingOptions,
  setPriceLoading,
  setRingPrice,
  setRingPriceError
};

const connector = connect(mapStateToProps, mapDispatchToProps);
export type PropsFromCartRedux = ConnectedProps<typeof connector>;

export default connector(Cart);
