import {useCallback, useState} from "react";
import {FilterContentType} from "../../types";
import {getRingPrice} from "../../Utils/getRingPriceValue";

const tooltipPadding ={
    carat: '10px 0px',
    color: '1rem 0px',
    clarity:'1rem 0px'
}

const useDiamondFilterContent = (props:FilterContentType) => {
    const {
        ring,
        footer,
        filter,
        setPriceLoading,
        setRingPrice,
        setRingPriceError,
        setFilter,
        removeFilterKey,
        setRingOptions,
        setBackToSelectDiamondPage,
        resetRemovedMinMax,
        toggleScrollTop
    } = props;
    const [SCS,setSCS] = useState<boolean>(filter.options['scs'] ? filter.options['scs'] as boolean : false);

    const handleFilterType = useCallback((value: string | string[] | boolean, key: string) => {
        if(key === "scs" && !value){
            return removeFilterKey('scs');
        }
        setFilter(key, value);
        if(["source"].includes(key) && Object.keys(ring.options).length > 0){
            setRingOptions("Diamond_Type" , value as string);
            const {Ring_Crown,Ring_Style,Ring_Side,Metal,Wedding_Band,Center_Diamond_Size} = ring.options;
            // const ringPriceData = `${footer.styleID}-${Ring_Style}${Ring_Crown}-${Diamond_Shape}-${Center_Diamond_Size}-${Ring_Side}-${Metal}-${Color}-${Diamond_Type}`;
            const ringPriceData = `${footer.styleID}-${Ring_Style}${Ring_Crown}-RND-${Ring_Style === 'STS' ? Center_Diamond_Size : 200}-${Ring_Side}-${Metal}-WG-${value}`;
            const priceData = {
                sku: `'${ringPriceData}'` ,
                order_items: Wedding_Band === "No Band" ? 1 : Wedding_Band === "Single" ? 2 : 3
            }
            getRingPrice(setPriceLoading,setRingPrice,setRingPriceError,priceData);
        }
    },[removeFilterKey,ring.options,footer.styleID,setFilter,setRingOptions,setPriceLoading,setRingPrice,setRingPriceError,getRingPrice]);

    const handleChangeDiamondType = useCallback((name:string)=>{
        // setChangeMinMaxValue(true);
        toggleScrollTop(false);
        setBackToSelectDiamondPage(false);
        if(name === 'Natural'){
            removeFilterKey("scs");
        }else if(name !== 'Natural' && Object.keys(filter.options).includes('scs')){
            handleFilterType(false,"scs");
            setSCS(false);
        }
        // if (Object.keys(ring.options).length < 1 && filter.options.source === (name === 'Natural' ? 'GNU' : 'LGN')) {
        if (filter.options.source === (name === 'Natural' ? 'GNU' : 'LGN')) {
            if(!["Jenny Packham","Reve"].includes(ring.extraData.Label) || ring.extraData.Label === ''){
                resetRemovedMinMax();
                return removeFilterKey("source");
            }
        }
        if(!(filter.options.source === (name === 'Natural' ? 'GNU' : 'LGN'))){
            handleFilterType((name === 'Natural' ? 'GNU' : 'LGN'), "source")
            resetRemovedMinMax();
        }
        // removeFilterKey("lab");
    },[toggleScrollTop,setBackToSelectDiamondPage,removeFilterKey,filter.options,handleFilterType,setSCS,ring.extraData,resetRemovedMinMax])

    return {
        handleFilterType,
        SCS,
        setSCS,
        tooltipPadding,
        handleChangeDiamondType
    }
}
export default useDiamondFilterContent;
